@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

// FONTS
@font-face {
	font-family: 'Panama Mono';
	src: url('../../dist/font/PanamaMonospace-Regular.woff2') format('woff2'),
			url('../../dist/font/PanamaMonospace-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Panama';
	src: url('../../dist/font/Panama-Italic.woff2') format('woff2'),
			url('../../dist/font/Panama-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Panama';
	src: url('../../dist/font/Panama-Bold.woff2') format('woff2'),
			url('../../dist/font/Panama-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}



// --
html {
	background: $black;
	font-family: 'Panama Mono', Arial, sans-serif;
	font-size: 16px;
	line-height: 1;
	background-image: url('../../dist/img/bg.jpg');

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

.btn{
	background-color: $white;
	display: inline-block;
	color: $black;
	font-size: 22px;
	border: solid 1px transparent;
	text-transform: uppercase;
	padding: 14px 30px 10px 30px;
	@include hover-focus{
		background-color: $black;
		color: $white;
		border-color: $white;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

// header {}

h1,h2,h3,h4,h5,p,ul,li,a{
	margin: 0;
	padding: 0;
}
main {
	section{
		padding: 40px 0;
		&.hero{
			min-height: 100vh;
			.container{
				h3{
					text-align: center;
					font-size: 35px;
					font-family: 'Panama Mono';
					font-weight: 400;
					margin-bottom: 50px;
				}
				.wrapper{
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
					img{
						max-height: 500px;
						display: block;
						margin: 0;
						position: relative;
						mix-blend-mode: multiply;
						opacity: .5;
					}
					.content{
						position: absolute;
						text-align: center;
						h2{
							font-size: 70px;
							font-weight: 400;
						}
						p{
							font-size: 29px;
						}
					}
				}
			}
			@include tablet-up{
				.container{
					height: calc(100vh - 80px);
					display: flex;
					flex-direction: column;
					justify-content: center;
					h3{
						margin-bottom: 0;
					}
					.wrapper{
						img{
							max-height: 600px;
						}
						.content{
							h2{
								font-size: 96px;
							}
							p{
								font-size: 35px;
							}
						}
					}
				}
			}
			&.hero-footer{
				min-height: 100%;
				padding-bottom: 0;
				.container{
					height: 100%;
					.wrapper{
						.content{
							h2{
								font-size: 30px;
							}
						}
						img{
							max-height: 320px;
							opacity: 1;
						}
					}
				}
				@include tablet-up{
					padding-bottom: 40px;
					.container{
						.wrapper{
							.content{
								h2{
									font-size: 70px;
								}
							}
						}
					}
				}
			}
		}

		&.about{
			.container{
				.wrapper{
					position: relative;
					padding: 70px 20px;
					border-radius: 10px;
					background-color: $white;
					p{
						color: $black;
						font-size: 16px;
						margin-bottom: 16px;
						text-align: center;
						line-height: 1.4;
						img{
							display: inline-block;
							width: 40px;
							transform: translateY(4px);
						}
					}
				}
			}
			@include tablet-up{
				.container{
					padding: 0 60px;
					.wrapper{
						p{
							font-size: 18px;
							max-width: 826px;
							margin: 0 auto 20px;
						}
					}
				}
			}
		}

		&.steps{
			padding: 40px 0;
			.container{
				h2{
					font-size: 35px;
					text-align: center;
					font-weight: 400;
					margin-bottom: 40px;
				}
				ul{
					list-style: none;
					li{
						font-size: 16px;
						text-align: center;
						margin-bottom: 40px;
						line-height: 1.4;
						&:last-child{
							margin-bottom: 0;
						}
						span{
							font-family: 'Panama';
							font-weight: 400;
							font-style: italic;
							display: block;
						}
					}
				}
			}
			@include tablet-up{
				.container{
					ul{
						li{
							font-size: 18px;
							margin-bottom: 50px;
						}
					}
				}
			}
		}

		&.breathe{
			padding: 65px 0;
			.container{
				.wrapper{
					position: relative;
					margin: 0 auto;
					height: 320px;
					width: 320px;
					max-width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					.bubble-back{
						width: 320px;
						max-width: 100%;
						height: 320px;
						border-radius: 300px;
						background-color: #edefcf9e;
						position: absolute;
						animation-name: pulse2;
						animation-duration: 6s;
						animation-iteration-count: infinite;
					}
					.bubble-back-2{
						width: 270px;
						max-width: 100%;
						height: 270px;
						border-radius: 300px;
						background-color: $white;
						position: absolute;
						animation-name: pulse1;
						animation-duration: 6s;
						animation-iteration-count: infinite;
					}
					.bubble{
						width: 270px;
						max-width: 100%;
						height: 270px;
						border-radius: 270px;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: transparent;
						position: relative;
						h3{
							font-size: 35px;
							text-transform: uppercase;
							text-align: center;
							font-weight: 400;
							color: $black;
							transform: translateY(10px);
						}
					}
					@keyframes pulse1 {
						0%   {transform: scale(1);}
						50%   {transform: scale(1.1);}
						100%   {transform: scale(1);}
					}
					@keyframes pulse2 {
						0%   {
							transform: scale(1);
						}
						50%   {
							transform: scale(1.2);
						}
						100%   {
							transform: scale(1);
						}
					}
				}
			}
			@include tablet-up{
				padding: 80px 0;
			}
		}

		&.video{
			padding: 60px 0;
			.container{
				h3{
					font-size: 17px;
					font-weight: 400;
					text-align: center;
					margin-bottom: 20px;
				}
				iframe{
					margin: 0 auto;
					border-radius: 20px;
				}
			}
			@include tablet-up{
				.container{
					iframe{
						width: 748px;
						height: 382px;
					}
				}
			}
		}

		&.music{
			.container{
				.player{
					&.sticked{
						position: fixed;
						top: 0;
						left: 0;
						width: 100%;
						background-color: $black;
						z-index: 999999999999;
						border-radius: 0;
					}
					transition: all .4s;
					position: relative;
					border: solid 1px $white;
					border-radius: 13px;
					display: flex;
					.song{
						display: flex;
						flex: 1;
						.number{
							border-right: solid 1px $white;
							padding: 10px;
							line-height: 1;
							flex: 1;
							max-width: max-content;
							span{
								&:first-child{
									display: none;
								}
							}
						}
						.name{
							border-right: solid 1px $white;
							padding: 10px;
							line-height: 1;
							flex: 1;
						}
					}
					button{
						&.btn-player{
							&.play{
								border-right: solid 1px $white;
								padding: 10px;
								line-height: 1;
								flex: 1;
								max-width: max-content;
								span{
									display: none;
								}
							}
							&.pause{
								padding: 10px;
								line-height: 1;
								flex: 1;
								max-width: max-content;
								span{
									display: none;
								}
							}
						}
					}
					button, p, .tracklist{
						background-color: transparent;
						color: $white;
						font-size: 18px;
						text-transform: uppercase;
						padding: 0;
						border: none;
						letter-spacing: 1px;
					}
					.tracklist{
						background-color: $white;
						color: $black;
						position: absolute;
						z-index: 99999;
						opacity: 0;
						pointer-events: none;
						transform: translateY(-6px);
						pointer-events: none;
						transition: all .4s;
						padding: 10px;
						top: 40px;
						border-radius: 13px;
						.trigger{
							display: none;
							pointer-events: none;
						}
						&.active{
							opacity: 1;
							transform: translateY(0);
							pointer-events: all;
						}
						.track{
							color: $black;
							padding: 10px 0;
							display: block;
						}
					}
					@include tablet-up{
						.song{
							flex: 2;
							.name{
								text-align: center;
							}
							.number{
								display: flex;
								max-width: 142px;
								justify-content: center;
								span{
									&:first-child{
										display: block;
										margin-right: 10px;
										max-width: initial;
									}
								}
							}
						}
						button.btn-player{
							&.play{
								order: 3;
								max-width: 135px;
								span{
									display: block;
								}
								i{
									display: none;
								}
							}
							&.pause{
								order: 4;
								max-width: 135px;
								span{
									display: block;
								}
								i{
									display: none;
								}
							}
						}
						.tracklist{
							display: flex;
							flex: 2;
							justify-content: center;
							align-items: center;
							opacity: 1;
							pointer-events: all;
							transform: translateY(0);
							position: initial;
							background-color: transparent;
							color: $white;
							border-right: solid 1px $white;
							border-radius: 0;
							order: 2;
							.trigger{
								display: block;
							}
							.tracks{
								background-color: $white;
								width: 100%;
								position: absolute;
								display: none;
								top: 38px;
								padding: 0 20px;
								border-radius: 0 0 13px 13px;
								&.active{
									display: block;
								}
							}
						}
					}
				}
			}
		}

		&.manifesto{
			.container{
				.wrapper{
					position: relative;
					border-radius: 10px;
					background-color: $white;
					position: relative;
					&::after{
						content: "";
						background-image: url('../../dist/img/leave-02.png');
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						transform: rotate(-90deg);
						height: 130px;
						width: 90px;
						display: block;
						margin: 0 auto;
					}
					h2{
						text-align: center;
						font-weight: 400;
						font-size: 35px;
						text-transform: uppercase;
						color: $black;
						padding: 40px 20px 20px;
					}
					.manifesto-slider{
						.manifesto-slide{
							width: 300px;
							height: 380px;
							margin: 0 10px;
							object-fit: cover;
						}
					}
				}
			}
			@include tablet-up{
				.container{
					.wrapper{
						padding: 0 90px 110px;
						background-color: $white;
						h2{
							padding: 60px 20px 40px;
						}
						.manifesto-slider{
							.manifesto-slide{
								width: 340px;
								height: 480px;
								cursor: zoom-in;
							}
						}
						&::after{
							content: "";
							transform: none;
							height: 130px;
							width: 110px;
							margin: 0;
							position: absolute;
							left: 10px;
							bottom: 10px;
						}
						&::before{
							content: "";
							background-image: url('../../dist/img/leave-01.png');
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;
							height: 130px;
							width: 120px;
							position: absolute;
							right: 10px;
							top: 10px;
						}
					}
				}
			}
		}
	}
	.manifesto-popup{
		background-color: rgba(0, 0, 0, 0.781);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		padding: 20px 0;
		transition: all .4s;
		opacity: 0;
		pointer-events: none;
		z-index: 99999999999999;
		&.active{
			opacity: 1;
			pointer-events: all;
		}
		.wrapper{
			position: relative;
			button{
				position: absolute;
				top: calc(50% - 20px);
				display: block;
				background-color: transparent;
				color: $white;
				border: none;
				font-size: 40px;
				&.prev{
					left: 20px;
				}
				&.next{
					right: 20px;
				}
			}
			img{
				max-height: 90vh;
				margin: 0 auto;
				transition: all .4s;
			}
			.close{
				cursor: pointer;
				top: 0;
				right: 0;
				position: absolute;
				width: 40px;
				height: 40px;
				background-color: $black;
				color: $white;
				padding: 0;
				margin: 0;
				border: none;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 30px;
			}
		}
	}
}

footer {
	padding: 0 0 5px;
	.container{
		text-align: center;
		.social{
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			a{
				display: block;
				font-size: 20px;
				margin-right: 16px;
				transition: all .4s;
				@include hover-focus{
					transform: translateY(-4px);
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
		.copyright{
			font-size: 12px;
			text-transform: uppercase;
			text-align: center;
			line-height: 1.4;
		}
	}
	@include tablet-up{
		padding-top: 40px;
	}
}

.paper-background{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
	opacity: .5;
}
.content{
	position: relative;
}